import { faGrid } from '@fortawesome/pro-solid-svg-icons';
import * as detect from 'detect.js';
window.detect = detect;

(function() {
	angular.module('application.header').controller('ApplicationHeaderCtrl', controller);

	controller.$inject = [
		'$window',
		'$state',
		'$scope',
		'$rootScope',
		'RouteHelpers',
		'ApplicationUserService',
		'ManagerAPI',
		'UserRole',
		'Localize',
		'NotificationFactory',
		'Portal',
		'ApiProducts',
		'FeatureFlagsService',
		'urls'
	];

	function controller(
		$window,
		$state,
		$scope,
		$rootScope,
		RouteHelpers,
		ApplicationUserService,
		ManagerAPI,
		UserRole,
		Localize,
		NotificationFactory,
		Portal,
		ApiProducts,
		FeatureFlagsService,
		urls
	) {
		// properties
		var $ctrl = this;
		$ctrl.legacyActivityMenuUrl = '/player#activityMenu';
		$ctrl.espanolActivityMenuUrl = 'https://spanish.imaginelearning.com/?activityMenu';
		$ctrl.espanolResourcesUrl =
			'https://content.imaginelearning.com/teacherResources_esp/documents/imagineEspanol_teacherResources.pdf';

		$ctrl.iOSDevice = /iPad|iPhone|iPod/.test($window.navigator.userAgent) && !$window.MSStream;

		$ctrl.productList = Portal.productList;
		$ctrl.products = undefined;
		$ctrl.siteCode = undefined;
		$ctrl.application = Portal;

		$ctrl.faGridProps = { icon: faGrid, size: '2xl', className: 'product-portal-grid' };

		// lifecycle events
		$ctrl.$onInit = onInit;
		$ctrl.$onChanges = onChanges;

		$ctrl.isEspanolSupportedBrowser = isEspanolSupportedBrowser;
		$ctrl.showEspanolUnsupportedBrowserMessage = showEspanolUnsupportedBrowserMessage;
		$ctrl.isCurrent = RouteHelpers.isCurrent;
		$ctrl.inDashboards = RouteHelpers.inDashboards;
		$ctrl.inEspanol = RouteHelpers.inEspanol;
		$ctrl.inActivityMenu = RouteHelpers.inActivityMenu;
		$ctrl.inOriginalReports = RouteHelpers.inOriginalReports;
		$ctrl.goToLectura = goToLectura;
		$ctrl.goToGalileo = goToGalileo;
		$ctrl.goToILE = goToILE;
		$ctrl.goToSpanish = goToSpanish;
		$ctrl.goToReader = goToReader;
		$ctrl.goToResources = goToResources;
		$ctrl.goToILLActionAreas = goToILLActionAreas;
		$ctrl.goToProductPortal = goToProductPortal;
		$ctrl.currentProduct = currentProduct;
		$ctrl.showSpanishLink = showSpanishLink;
		$ctrl.showILELink = showILELink;
		$ctrl.showGalileoLink = showGalileoLink;
		$ctrl.showReaderLink = showReaderLink;
		$ctrl.showLecturaLink = showLecturaLink;
		$ctrl.isChrome = isChrome;
		$ctrl.section = 'dashboard';
		$ctrl.isEspanolSupportedChrome = isEspanolSupportedChrome;
		$ctrl.hideActionAreas = false;
		$ctrl.aatInterventionAssistantLabel = false;
		$ctrl.nonCCSSVersion = true; // default to true to avoid possibly having CCSS content visible before the FLAG is evaluated
		$ctrl.currentStateName = currentStateName;

		$scope.$on('groupDeleted', function(evt, data) {
			checkGroupCount();
		});

		$scope.$on('groupCreated', function(evt, data) {
			checkGroupCount();
		});

		/** Detects browser info and returns true if Chrome */
		function isChrome() {
			var info = $window.detect.parse($window.navigator.userAgent),
				browser = info.browser.family;
			// return browser && !!(browser.match(/^chrome/i));
			return browser && browser === 'Chrome';
		}

		function getChromeVersion() {
			var segments = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
			if (segments == null || segments.length != 5) {
				return undefined;
			}
			segments = segments.map(segment => parseInt(segment, 10));
			return {
				major: segments[1],
				minor: segments[2],
				build: segments[3],
				patch: segments[4]
			};
		}

		function isEspanolSupportedBrowser() {
			var version = getChromeVersion();
			return !!$ctrl.iOSDevice && !!version && version.major < 72;
		}

		function isEspanolSupportedChrome() {
			var version = getChromeVersion();
			return !!version && version.major < 72;
		}

		function showSpanishLink() {
			return _.indexOf($ctrl.products, ApiProducts.Spanish) !== -1;
		}

		function showILELink() {
			return _.indexOf($ctrl.products, ApiProducts.ILE) !== -1;
		}

		function showGalileoLink() {
			return _.indexOf($ctrl.products, ApiProducts.Galileo) !== -1;
		}

		function showReaderLink() {
			return _.indexOf($ctrl.products, ApiProducts.Reader) !== -1;
		}

		function showLecturaLink() {
			return _.indexOf($ctrl.products, ApiProducts.Enyay) !== -1 || _.indexOf($ctrl.products, ApiProducts.Lectura) !== -1;
		}

		function onInit() {
			if (!$ctrl.user) {
				return;
			}

			$ctrl.section = getSection();

			$ctrl.userIsImpersonating = ApplicationUserService.impersonating();
			$ctrl.products = ApplicationUserService.getUser().products;
			$ctrl.userIsAdmin =
				$ctrl.user.role === UserRole.admin || $ctrl.user.role === UserRole.ilAdmin || $ctrl.user.role === UserRole.ilSiteAdmin;
			$ctrl.userIsILAdmin = $ctrl.user.role === UserRole.ilAdmin || $ctrl.userIsImpersonating === true;
			$ctrl.userIsILSiteAdmin = $ctrl.user.role === UserRole.ilSiteAdmin || $ctrl.userIsImpersonating === true;
			if ($ctrl.user.role === UserRole.teacher || $ctrl.user.role === UserRole.parent) {
				$ctrl.managerName = Localize.translateInstant('application_header.teachersManager');
			} else {
				$ctrl.managerName = Localize.translateInstant('application_header.manager');
			}

			if ($ctrl.user.organizationIds && $ctrl.user.organizationIds.length) {
				ManagerAPI.Organization.siteCode($ctrl.user.organizationIds[0])
					.then(function(result) {
						$ctrl.siteCode = result;
					})
					.catch(function(error) {
						var status = _.get(error, 'status');
						if (status !== 404) {
							NotificationFactory.error(error);
						}
					});
			}

			FeatureFlagsService.evaluate('aat-intervention-assistant').then(function(result) {
				$ctrl.aatInterventionAssistantLabel = !!result && result === 'show';

				return FeatureFlagsService.evaluateKey('non-ccss-literacy').then(result => {
					$ctrl.nonCCSSVersion = !!result && result === 'show';
				});
			});

			checkGroupCount();
		}

		function checkGroupCount() {
			if (!$ctrl.userIsImpersonating && ($ctrl.userIsILAdmin || $ctrl.userIsILSiteAdmin)) {
				$ctrl.hideActionAreas = true;
				return;
			}

			ManagerAPI.User.get(ApplicationUserService.getUser().id).then(user => {
				var groupCount = !!(user && user.groupIds && user.groupIds.length);
				$ctrl.hideActionAreas = !groupCount;
			});
		}

		function onChanges(changesObj) {
			if (changesObj.user && changesObj.user.currentValue) {
				onInit();
			}
		}

		function showEspanolUnsupportedBrowserMessage() {
			NotificationFactory.alert({
				heading: Localize.translateInstant('application_header.unsupportedPlatform'),
				content: Localize.translateInstant('application_header.unsupportedPlatformMessage')
			});
		}

		function currentProduct() {
			return Portal.product;
		}

		function goToILE() {
			$state.go(
				ApplicationUserService.getUserRole() === UserRole.admin ? 'dashboard.organization' : 'dashboard.user',
				{},
				{
					notify: true,
					reload: true
				}
			);
		}

		function goToSpanish() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.espanolUrl);
		}

		function goToGalileo() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.galileoUrl);
		}

		function goToReader() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.readerUrl);
		}

		function goToLectura() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.lecturaUrl);
		}

		function goToResources() {
			const resourcesUrl = currentProduct() === ApiProducts.ILE ? '/resources/home' : '/resources_esp/home';
			$window.open($rootScope.appendAuthSourceQueryParam(resourcesUrl));
		}

		function goToILLActionAreas() {
			$window.open($rootScope.appendAuthSourceQueryParam('/action-areas'));
		}

		function goToProductPortal() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.productSelection);
		}

		function getSection() {
			const location = window.location.toString();
			if (location.contains('playlist')) {
				return 'playlist';
			}

			if (location.contains('activities')) {
				return 'activities';
			}

			if (location.contains('action-areas')) {
				return 'action-areas';
			}

			if (location.contains('resources')) {
				return 'resources';
			}

			return 'dashboard';
		}

		function currentStateName() {
			return $state.current.name;
		}
	}
})();
